import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegramPlane, faViber } from '@fortawesome/free-brands-svg-icons';

import CustomA from 'components/ui/links/CustomA';
import { CONTACTS } from 'domain/constants/CONTACTS';

const Contacts: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.socialContainer}>
        <CustomA href={CONTACTS.instagramUrl}>
          <FontAwesomeIcon icon={faInstagram} size="3x" className={classes.icon} />
        </CustomA>

        <CustomA href={`viber://chat/?number=${CONTACTS.viber}`}>
          <FontAwesomeIcon icon={faViber} size="3x" className={classes.icon} />
        </CustomA>

        <CustomA href={`https://telegram.me/${CONTACTS.telegram}`}>
          <FontAwesomeIcon icon={faTelegramPlane} size="3x" className={classes.icon} />
        </CustomA>
      </Box>

      <CustomA href={`mailto:${CONTACTS.email}`}>
        <Typography component="h5" variant="h4" className={classes.email}>
          {CONTACTS.email}
        </Typography>
      </CustomA>

      <Typography variant="caption" className={classes.address}>
        Україна, 81137, Львівська обл., Львівський р-н, село Пасіки-Зубрицькі
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  socialContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  icon: {
    color: theme.palette.common.white
  },
  email: {
    color: theme.palette.common.white,
    textAlign: 'center'
  },
  address: {
    color: theme.palette.common.white,
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: 'block',
    maxWidth: '80%',
    margin: 'auto'
  }
}));

export default Contacts;
