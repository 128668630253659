import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import { extraPalette } from 'theme/palette';

interface QuantityControlsProps {
  quantity: number;
  available: number | undefined;
  onIncrease: () => void;
  onDecrease: () => void;
}

const QuantityControls: React.FC<QuantityControlsProps> = ({
  quantity,
  available,
  onIncrease,
  onDecrease
}): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.container}>
        <Button onClick={onDecrease} disabled={quantity <= 1} className={classes.button}>
          <FontAwesomeIcon icon={faMinus} />
        </Button>

        <Typography variant="h5" component="span" className={classes.text}>
          {quantity}
        </Typography>

        <Button onClick={onIncrease} className={classes.button}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </Box>

      <Typography className={classes.availabilityMessage}>
        {available && available > 0 && quantity > available
          ? `${available} шт. товару в наявності`
          : ' '}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 42,
    width: 126,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: extraPalette.grey.light
  },
  text: {
    fontSize: '1.5rem'
  },
  button: {
    minWidth: 'unset',
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`
  },
  availabilityMessage: {
    color: theme.palette.error.main,
    whiteSpace: 'pre'
  }
}));

export default QuantityControls;
